import React from 'react';
import { API } from '../../../api'
import { AiOutlineLineChart } from 'react-icons/ai'
import { IoBarChartOutline } from 'react-icons/io5'
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { useTranslation } from 'react-i18next';

const UpdateCard = ({ editChart, dashboard, setDownloadBtnActive, getCards, getUpdateCards }) => {
    const { t } = useTranslation()

    const handleOnchangeGroup = async (e) => {
        const updateData = dashboard.cards.map((val) => {
            console.log(val)
            const toDate = moment(localStorage.getItem('toDate'))
            const fromDate = moment(localStorage.getItem('fromDate'))
            const diff = toDate.diff(fromDate, 'days')
            if(val.type !== 'Device Chart' && val.type !== 'Electric Bill Chart'){
                if(e === '1m' && diff > 4){
                    alert('Date range should be less than 4 days for 1 mins interval.')
                    return val;
                }
                else{
                    for (const group of val.groups) {
                        if ((''+group.unit).toLowerCase() !== 'kwh') {
                            for (const device of group.devices) {
                                device.group_as = e
                            }
                        }
                        else{
                            for (const device of group.devices) {
                                device.group_as = '30d'
                            }
                        }
                    }
                    val.groupAs = e
                    return val
                }
            }
            else{
                return val;
            }
        })

        const finalData = {
            method: 'PATCH',
            data : {
                ...dashboard,
                cards: updateData
            }
        }

        const response = await API(`/api/update/dashboard/${ dashboard._id }`, finalData)
        if(response.success){
            window.location.reload()
            // getUpdateCards()
        }
    }

    const handleOnchangeMode = async (e) => {
        const updateData = dashboard.cards.map((val) => {
            if(val._id === editChart._id){
                for (const group of val.groups) {
                    for (const device of group.devices) {
                        device.mode = e
                    }
                }
                val.mode = e
            }
            return val
        })
        const finalData = {
            method: 'PATCH',
            data : {
                ...dashboard,
                cards: updateData
            }
        }

        const response = await API(`/api/update/dashboard/${ dashboard._id }`, finalData)
        if(response.success){
            window.location.reload()
            // getUpdateCards()
        }
    }

    const handleOnchangeLine = async (e) => {
        const updateData = dashboard.cards.map((val) => {
            if(val._id === editChart._id){
                val.type = e
            }
            return val
        })
        const finalData = {
            method: 'PATCH',
            data : {
                ...dashboard,
                cards: updateData
            }
        }

        const response = await API(`/api/update/dashboard/${ dashboard._id }`, finalData)
        if(response.success){
            // window.location.reload()
            getCards()
        }
    }

    const handleOnchangeBar = async (e) => {
        const updateData = dashboard.cards.map((val) => {
            if(val._id === editChart._id){
                val.type = e
            }
            return val
        })
        const finalData = {
            method: 'PATCH',
            data : {
                ...dashboard,
                cards: updateData
            }
        }

        const response = await API(`/api/update/dashboard/${ dashboard._id }`, finalData)
        if(response.success){
            // window.location.reload()
            getCards()
        }
    }

    const handleOnchangeFromDate = async (e) => {
        const date = moment(e[ 0 ]).format('YYYY-MM-DD')

        const updateData = dashboard.cards.map((val) => {
            if(val._id === editChart._id){
                const group = val.groups.map((grp) => {
                    grp.fromDate = date
                    return grp
                })
                val.groups = group
            }
            return val
        })

        const finalData = {
            method: 'PATCH',
            data : {
                ...dashboard,
                cards: updateData
            }
        }

        const response = await API(`/api/update/dashboard/${ dashboard._id }`, finalData)
        if(response.success){
            // window.location.reload()
        }
    }

    const handleOnchangeToDate = async (e) => {
        const date = moment(e[ 0 ]).format('YYYY-MM-DD')
        const updateData = dashboard.cards.map((val) => {
            if(val._id === editChart._id){
                const group = val.groups.map((grp) => {
                    grp.toDate = date
                    return grp
                })
                val.groups = group
            }
            return val
        })

        const finalData = {
            method: 'PATCH',
            data : {
                ...dashboard,
                cards: updateData
            }
        }

        const response = await API(`/api/update/dashboard/${ dashboard._id }`, finalData)
        if(response.success){
            // window.location.reload()
        }
    }

    return (
        <>
            <div>
                <div className='grid grid-row-2 gap-[20px] w-full bg-white p-5 border border-gray justify-center'>

                    {/* <div className='w-max align-middle flex flex-row'>
                        <label htmlFor="date" className="block text-sm font-medium text-[#5a7bad] mr-3 mt-3">
                            {t('From')}
                        </label>
                        <div className="mt-1 relative w-fit" style={ { zIndex:'999999' } }>
                            <Flatpickr
                                style={ { zIndex:'999999' } }
                                className='w-full rounded-[8px] text-sm'
                                value={ editChart?.groups?.[ 0 ]?.fromDate }
                                placeholder="mm/dd/yyyy"
                                onChange={ (date) =>  handleOnchangeFromDate(date) }
                            />
                        </div>
                    </div>
                    <div className='w-max align-middle flex flex-row'>
                        <label htmlFor="date" className="block text-sm font-medium text-[#5a7bad]  mr-3 mt-3">
                            {t('To')}&nbsp;&nbsp;&nbsp;&nbsp;
                        </label>
                        <div className="mt-1 relative w-fit" style={ { zIndex:'999999' } }>
                            <Flatpickr
                                style={ { zIndex:'999999' } }
                                value={ editChart?.groups?.[ 0 ]?.toDate }
                                className='w-full rounded-[8px] text-sm'
                                placeholder="mm/dd/yyyy"
                                onChange={ (date) => handleOnchangeToDate(date) }
                            />
                        </div>
                    </div> */}

                    {/* <div>
                        <button onClick={ () => setDashboardata(val) } className='text-[#fff] text-[14px] rounded-md py-[1px] px-[10px] bg-[#599bfb] font-medium mb-3'>{t('Confirm')}</button>
                    </div> */}
                    <div className='flex gap-3 justify-center'>

                        {(editChart?.type === 'Line Chart' || editChart?.type === 'Bar Chart') &&
                        <button onClick={ () => handleOnchangeLine('Line Chart') } className={ `flex h-[40px] rounded-[6px] w-[40px] justify-center border-[2px] ${ editChart?.type === 'Line Chart' ? 'border-[#5a7bad]' : 'border-[#c0c0c0]' } items-center font-medium` }>
                            <AiOutlineLineChart className={ `text-[26px] ${ editChart?.type === 'Line Chart' ? 'text-[#5a7bad]' : 'text-[#504c4c]' } ` } />
                        </button>}
                        {(editChart?.type === 'Line Chart' || editChart?.type === 'Bar Chart') &&
                        <button onClick={ () => handleOnchangeBar('Bar Chart') } className={ `flex h-[40px] rounded-[6px] w-[40px] justify-center border-[2px] ${ editChart?.type === 'Bar Chart' ? 'border-[#5a7bad]' : 'border-[#c0c0c0]' } items-center font-medium` }>
                            <IoBarChartOutline className={ `text-[26px] ${ editChart?.type === 'Bar Chart' ? 'text-[#5a7bad]' : 'text-[#504c4c]' } ` } />
                        </button>
                        }

                        <select id="name" name="name" type="name"
                            value={ editChart?.groupAs }
                            onChange={ (e) => handleOnchangeGroup(e.target.value) }
                            className="appearance-none block w-max px-4 py-2 border border-gray-300 rounded-xl outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm">
                            <option value="1m">1 mins</option>
                            <option value="15m">15 mins</option>
                            <option value="30m">30 mins</option>
                            <option value="1h">Hour</option>
                            <option value="1d">Day</option>
                            <option value="7d">Week</option>
                            <option value="30d">Month</option>
                        </select>

                        <select id="name" name="name" type="name"
                            value={ editChart?.mode }
                            onChange={ (e) => handleOnchangeMode(e.target.value) }
                            className="appearance-none block w-max px-4 py-2 border border-gray-300 rounded-xl outline-none focus:shadow-[inset_0px_0px_3px_0px_rgba(71,71,71,0.55);] placeholder-gray-400 text-gray-900 sm:text-sm">
                            <option value="Average">Avg</option>
                            <option value="Sum">Sum</option>
                            <option value="Max">Max</option>
                            <option value="Min">Min</option>
                            <option value="Count">Count</option>
                            <option value="String">String</option>
                        </select>
                    </div>
                    <div className='w-100' style={ { textAlign: '-webkit-center' } }>
                        <button onClick={ () => {setDownloadBtnActive('') } } className='w-fit py-[4px] px-[20px] border-[2px] border-[#599bfb] text-[#599bfb] transition-all font-semibold rounded-md flex justify-end'>{t('Confirm')}</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateCard