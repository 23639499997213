import { toast } from 'react-toastify'
import { API } from '../../api'
import React, { useEffect, useState } from 'react';
import BarLoader from 'react-spinners/BarLoader';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import { io } from 'socket.io-client'
import moment from 'moment';
const socket = io('wss://system.innovura.app:3000/')
socket.connect()

const ElectricBillCard = ({ id, dashboardId, refresh, toDate, fromDate, val, dashboard }) => {

    const [ loading, setLoading ] = useState(true)
    const [ data, setData ] = useState([])
    const [ processData, setProcessData ] = useState({})
    const [ mqttData, setMqttData ] = useState([]);
    const { t } = useTranslation()
    const [ color, setColor ] = useState('green');

    const triggerColorChange = () => {
        setColor('yellow');
        setTimeout(() => {
            setColor('green');
        }, 500); // Change back to green after 500 milliseconds
    };
    socket.on('connect', () => {
        console.log('connected')
    })

    const AddDeviceSlave = async() => {
        try {
            const ip = (''+val.groups[ 0 ].devices[ 0 ].iot_host).replace('Nano-').replace('-', '.') // Nano-0-178
            const APIDATA = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { ip_address: ip, iotId:val.groups[ 0 ].devices[ 0 ].iotId }
            };
            const response = await API('/api/deviceslaves/list/', APIDATA); // Updated the endpoint URL
            if (response) {
                toast.success(response.message, { autoClose: 5000 });
                // fetchSlavesData();
            }
        } catch (error) {
            toast.success(error.message, { autoClose: 5000 });
        }
    }

    function handleMqttData(dat, topic) {
        console.log(val)
        // console.log((''+val.groups[ 0 ].devices[ 0 ].iot_host))
        if (topic == 'loggerdata/'+(''+val.groups[ 0 ].devices[ 0 ].iot_host) ) {
            setMqttData(dat)
            console.log(dat)
            const data = JSON.parse(dat)

            const currentL1 = Math.abs(parseFloat((data[ 'Current A' ]))/ (dashboard.currentCalc !== undefined ? dashboard.currentCalc : 1) ).toFixed(1) || '-';
            const currentL2 = Math.abs(parseFloat((data[ 'Current B' ]))/ (dashboard.currentCalc !== undefined ? dashboard.currentCalc : 1) ).toFixed(1) || '-';
            const currentL3 = Math.abs(parseFloat((data[ 'Current C' ]))/ (dashboard.currentCalc !== undefined ? dashboard.currentCalc : 1) ).toFixed(1) || '-';
            const voltageL1 = Math.abs(parseFloat((data[ 'Voltage A' ]))/ (dashboard.voltCalc !== undefined ? dashboard.voltCalc : 1) ).toFixed(1) || '-';
            const voltageL2 = Math.abs(parseFloat((data[ 'Voltage B' ]))/ (dashboard.voltCalc !== undefined ? dashboard.voltCalc : 1) ).toFixed(1) || '-';
            const voltageL3 = Math.abs(parseFloat((data[ 'Voltage C' ]))/ (dashboard.voltCalc !== undefined ? dashboard.voltCalc : 1) ).toFixed(1) || '-';
            const kW = parseFloat( Math.abs((data[ 'L1 KW' ]) + Math.abs(data[ 'L2 KW' ]) + Math.abs(data[ 'L3 KW' ])) / (dashboard.kVAHistCalc !== undefined ? dashboard.kVAHistCalc : 1) ).toFixed(1) || 0;
            const kVA = parseFloat((Math.abs(data[ 'L1 KVA' ]) + Math.abs(data[ 'L2 KVA' ]) + Math.abs(data[ 'L3 KVA' ])) / (dashboard.kVAHistCalc !== undefined ? dashboard.kVAHistCalc : 1) ).toFixed(1) || 0;
            const PF = (
                parseFloat(
                    (
                        Math.abs(data[ 'PF A' ]) / (dashboard.pfCalc !== undefined ? dashboard.pfCalc : 1) +
                        Math.abs(data[ 'PF B' ]) / (dashboard.pfCalc !== undefined ? dashboard.pfCalc : 1) +
                        Math.abs(data[ 'PF C' ]) / (dashboard.pfCalc !== undefined ? dashboard.pfCalc : 1)
                    ) / 3
                ).toFixed(1)
            )  || 0;
            console.log(PF, dashboard.pfCalc)

            const currentData = [ currentL1, currentL2, currentL3 ] || [];
            const voltageData = [ voltageL1, voltageL2, voltageL3 ] || [];
            const activePower = kW || 0;
            const apparentPower = kVA || 0;
            const powerFactor = PF || 0;

            setProcessData({
                currentData,
                voltageData,
                activePower,
                apparentPower,
                powerFactor,
                todayPeak: processData.todayPeak,
                historyPeak: processData.historyPeak,
                monthlyPowerkWh: processData.monthlyPowerkWh,
                historicalPeakkvaDate: processData.historicalPeakkvaDate,
                todayPeakkvaDate: processData.todayPeakkvaDate,
                time:  moment.utc(data[ 'time' ]).local().format('YYYY-MM-DD HH:mm:ss')
            })
            triggerColorChange()
        }
    }

    socket.on('message', (e) => {
        if (e?.topic?.includes('loggerdata/'+(''+val.groups[ 0 ].devices[ 0 ].iot_host)) ) {
            handleMqttData(e.message, (''+e.topic))
        }
    })

    const getChartData = async (loading) => {
        console.log('getChartData called ElectricBillCard')
        setLoading(loading)
        try{
            const dat = {
                method: 'POST',
                data : {
                    _id : dashboardId,
                    cardId : id,
                    fromDate,
                    toDate
                }
            }
            const chartAPI = await API('/lightdata', dat)
            setLoading(false)
            setData(chartAPI.chartData.data)
            const data = chartAPI.chartData.data
            const currentL1 = data[ data.findIndex((item)=>{ return item.label == 'current_L1' }) ].buckets.y || '-';
            const currentL2 = data[ data.findIndex((item)=>{ return item.label == 'current_L2' }) ].buckets.y || '-';
            const currentL3 = data[ data.findIndex((item)=>{ return item.label == 'current_L3' }) ].buckets.y || '-';
            const voltageL1 = data[ data.findIndex((item)=>{ return item.label == 'voltage_L1' }) ].buckets.y || '-';
            const voltageL2 = data[ data.findIndex((item)=>{ return item.label == 'voltage_L2' }) ].buckets.y || '-';
            const voltageL3 = data[ data.findIndex((item)=>{ return item.label == 'voltage_L3' }) ].buckets.y || '-';
            const kW = data[ data.findIndex((item)=>{ return item.label == 'kw' }) ].buckets.y || 0;
            const kVA = data[ data.findIndex((item)=>{ return item.label == 'kva' }) ].buckets.y || 0;
            const PF = data[ data.findIndex((item)=>{ return item.label == 'pf' }) ].buckets.y || 0;
            const todayPeakkva = data[ data.findIndex((item)=>{ return item.label == 'today_peakkva' }) ].buckets.y || 0;
            const todayPeakkvaDate = moment.utc(data[ data.findIndex((item)=>{ return item.label == 'today_peakkva' }) ].buckets.x).local().format('YYYY-MM-DD HH:mm:ss') || processData?.todayPeakkvaDate || undefined;

            const historicalPeakkva = data[ data.findIndex((item)=>{ return item.label == 'historical_peakkva' }) ].buckets.y || 0;
            const historicalPeakkvaDate = moment.utc(data[ data.findIndex((item)=>{ return item.label == 'historical_peakkva' }) ].buckets.x).local().format('YYYY-MM-DD HH:mm:ss') || processData?.historicalPeakkvaDate || undefined;
            const monthlyKwh = data[ data.findIndex((item)=>{ return item.label == 'monthly_kwh' }) ].buckets.y || 0;

            const currentData = [ currentL1, currentL2, currentL3 ] || [];
            const voltageData = [ voltageL1, voltageL2, voltageL3 ] || [];
            const activePower = kW || 0;
            const apparentPower = kVA || 0;
            const powerFactor = PF || 0;
            const todayPeak = todayPeakkva || 0;
            const historyPeak = historicalPeakkva || 0;
            const monthlyPowerkWh = monthlyKwh || 0;

            setProcessData({
                currentData,
                voltageData,
                activePower,
                apparentPower,
                powerFactor,
                todayPeak,
                historyPeak,
                monthlyPowerkWh,
                historicalPeakkvaDate,
                todayPeakkvaDate,
                time: moment().format('YYYY-MM-DD HH:mm:ss')
            })

            console.log({
                currentData,
                voltageData,
                activePower,
                apparentPower,
                powerFactor,
                todayPeak,
                historyPeak,
                monthlyPowerkWh,
                historicalPeakkvaDate,
                todayPeakkvaDate,
                time: moment().format('YYYY-MM-DD HH:mm:ss')
            })

        }catch (error) {
            console.log(error)
            setLoading(false)
            toast.error(error, { autoClose: 5000 })
        }
    }

    useEffect(() => {
        if ((fromDate !== '' && toDate !== '') || (fromDate == '' && toDate == '')){
            getChartData(true)
            const interval = setInterval(() => {
                if(refresh){
                    getChartData(false)
                }
            }, 10000)
            return () => clearInterval(interval);
        }
    }, [ toDate, fromDate ])

    const setFieldsOld = () => {
        const fields = []
        {data?.map((items)=>{
            if (typeof items === Array){
                {items?.map((item)=>{
                    fields.push(
                        <span className='text-normal text-black font-bold text-center p-2'>{parseFloat(item?.buckets?.y).toFixed(1)} {item?.unit}<br></br><span className='text-[#5a7bad]'>{items?.label}</span></span>
                    )
                }) }
            }else{
                fields.push(
                    <div className='text-normal text-black font-bold text-center p-2'>{parseFloat(items?.buckets?.y).toFixed(1)} {items?.unit}<br></br><span className='text-[#5a7bad]'>{items?.label}</span></div>
                )
            }

        }) }
        return fields
    }

    const setFields = () => {
        const fields = [];
        const otherFields = [];
        const histoFields = [];

        // Header for Current Readings
        fields.push(
            <h2 className='text-xl font-bold text-center' key="current-header">3 Phase <span className='text-[#5a7bad]'>Current</span> Reading</h2>
        );
        fields.push(
            <div className='flex justify-evenly text-center' key="current-labels">
                <div className='font-bold text-[#5a7bad]'>L1</div>
                <div className='font-bold text-[#5a7bad]'>L2</div>
                <div className='font-bold text-[#5a7bad]'>L3</div>
            </div>
        );
        fields.push(
            <div className='flex justify-evenly text-center' key="current-values">
                {processData?.currentData?.map((item, index) => (
                    <span className='text-normal text-black font-bold text-center' key={ index }>
                        {parseFloat(item).toFixed(1)} A
                    </span>
                ))}
            </div>
        );

        // Header for Voltage Readings
        fields.push(
            <h2 className='text-xl font-bold text-center mt-4' key="voltage-header">3 Phase <span className='text-[#5a7bad]'>Voltage</span> Reading</h2>
        );
        fields.push(
            <div className='flex justify-evenly' key="voltage-labels">
                <span className='font-bold text-[#5a7bad]'>L1</span>
                <span className='font-bold text-[#5a7bad]'>L2</span>
                <span className='font-bold text-[#5a7bad]'>L3</span>
            </div>
        );
        fields.push(
            <div className='flex justify-evenly' key="voltage-values">
                {processData?.voltageData?.map((item, index) => (
                    <span className='text-normal text-black font-bold text-center' key={ index }>
                        {parseFloat(item).toFixed(1)} V
                    </span>
                ))}
            </div>
        );

        // Display Power Values
        otherFields.push(
            <div className='text-left mt-6' key="power-values">
                <div className='font-bold text-[#5a7bad] p-1 flex justify-between'>
                    <div className='mr-3'>Active Power (kW)</div>
                    <div className='justify-left'>
                        <span className='text-black'>: {parseFloat(processData?.activePower || 0).toFixed(1)}</span>
                    </div>
                </div>
                <div className='font-bold text-[#5a7bad] p-1 flex justify-between'>
                    <div className='mr-3'>Apparent Power (kVA)</div>
                    <div className='justify-left'>
                        <span className='text-black'>: {parseFloat(processData?.apparentPower || 0).toFixed(1)}</span>
                    </div>
                </div>
                <div className='font-bold text-[#5a7bad] p-1 flex justify-between'>
                    <div className='mr-3'>Power Factor</div>
                    <div className='justify-left'>
                        <span className='text-black '>: {parseFloat(processData?.powerFactor || 0).toFixed(1)}</span>
                    </div>
                </div>
                {/* <div className='mt-1'>
                    <h6>{moment().format('YYYY-MM-DD HH:mm:ss')}</h6>
                </div> */}
            </div>
        );

        // Display Power Values
        histoFields.push(
            // <div className='text-center' key="power-values">
            //     <div className='font-bold text-[#5a7bad] p-1'>
            //         Today Peak kVA: <span className='text-black'>{processData?.todayPeak?.toFixed(1)} (kVA)</span>
            //     </div>
            //     <div className='font-bold text-[#5a7bad] p-1'>
            //         Historical Highest Peak kVA: <span className='text-black'>{processData?.historyPeak?.toFixed(1)} (kVA)</span>
            //     </div>
            //     <div className='font-bold text-[#5a7bad] p-1'
            //         data-tooltip-id="Monthly_Power_Consumption"
            //         data-tooltip-variant="info"
            //     >
            //         Monthly Power Consumption: <span className='text-black'>{processData?.monthlyPowerkWh?.toFixed(1)} (kWh)</span>
            //     </div>
            // </div>

            <div className='text-center mt-6 pl-[10rem] pr-[10rem]' key="power-values">
                <div className='font-bold text-[#5a7bad] p-1 flex justify-between ml-7'>
                    <div className=''><p className='text-center'>Today Peak kVA</p><p className='text-xs'>({ processData?.todayPeakkvaDate })</p></div>
                    <div className='justify-left'>
                        <span className='text-black'>: {processData?.todayPeak?.toFixed(1)} (kVA)</span>
                    </div>
                </div>
                <div className='font-bold text-[#5a7bad] p-1 flex justify-between'>
                    <div className='mr-3'><p className='text-center'>Historical Highest Peak kVA</p><p className='text-xs'>({ processData?.historicalPeakkvaDate })</p></div>
                    <div className='justify-left mt-2'>
                        <span className='text-black'>: {processData?.historyPeak?.toFixed(1)} (kVA)</span>
                    </div>
                </div>
                <div className='font-bold text-[#5a7bad] p-1 flex justify-between'>
                    <div className='mr-3'><p>Monthly Power Consumption</p><p className='text-xs'>(From 1st of current Month)</p></div>
                    <div className='justify-left mt-2'>
                        <span className='text-black '>: {processData?.monthlyPowerkWh?.toFixed(1)} (kWh)</span>
                    </div>
                </div>
            </div>

        );
        return (
            <div className='flex justify-evenly'>
                <div className='border-2 border-blue-500 p-4 rounded-lg w-[50%] mr-1'>
                    <h1 className='text-2xl font-bold text-center mb-4'>Real-Time <span className='text-[#5a7bad]'>Power Usage </span></h1>

                    <div className='flex justify-evenly'>
                        <div>
                            {fields}
                        </div>
                        <div>
                            {otherFields}
                        </div>
                    </div>

                    <div className='mt-1'>

                        <p className='text-xs text-right'>
                            {processData.time}
                        </p>
                    </div>
                </div>
                <div className='border-2 border-blue-500 p-4 rounded-lg w-[50%] text-center ml-1'>
                    <h1 className='text-2xl font-bold text-center mb-4'>Other <span className='text-[#5a7bad]'>Power Data</span></h1>
                    {histoFields}
                </div>

            </div>
        );
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center">
                <BarLoader color={ '#5a7bad' } loading={ loading } size={ 150 } />
            </div>
        )
    }
    else{
        return (
            <>
                <div className='text-center mb-3 w-[100%] justify-center Office-card'>
                    {setFields()}
                </div>
                <Tooltip
                    id="Monthly_Power_Consumption"
                    place="top"
                    content={ t('Monthly_Power_Consumption') }
                />
            </>
        )
    }
}

export default ElectricBillCard